<template>
  <section class="cta-section--block">
    <div class="container">
      <article class="cta-content--wrap">
        <header class="entry-heading">
          <h3>{{ title }}</h3>
        </header>
        <section class="entry-content">
          <p>{{ text }}</p>
          <CtaButton type="intern" btnWidth btnArrow btnBlue :text='buttonText' :link="buttonLink" />
        </section>
      </article>
    </div>
  </section>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  name: "CtaBlock",
  props: ["title", "text", "buttonText", "buttonLink", "buttonColor", "buttonTextColor"],
  components: {
    CtaButton,
  },
};
</script>

<style lang="scss" scoped>
.cta-section--block {
  width: 100%;
  padding: 80px 0;
  background: $primary;
  @include tablet-down {
    padding: 48px 0;
  }
  .cta-content--wrap {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    color:white;
    .entry-heading {
      padding-bottom: 16px;
      @include tablet-down {
        padding-bottom: 12px;
      }
    }
    .entry-content {
      width: 100%;
      margin: 0 auto;

      .cta-btn--container {
        display: flex;
        margin-top: 32px;
        .cta-btn--default {
          display: inline-block;
          margin: 0 auto;
          padding: 12px 25px;
          color: white;
          background: $blue;
          span {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            position: relative;
            &::after {
              content: url("~@/assets/img/icons/arrow-right.svg");
              padding-left: 12px;
              line-height: 0;
            }
          }
          @include tablet-down {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>