<template>
  <article class="accordion-item">
    <div class="accordion-item--inner" :class="isOpen ? 'open' : ''">
      <div class="accordion-heading">
        <h3 @click="toggleAccordion()" :class="isOpen ? 'open' : ''">
          {{ accordionTitle }}
        </h3>
      </div>

      <div v-if="accordionContent.includes('<p>')" class="accordion-content" v-html="accordionContent"/>
      <div v-else class="accordion-content">
        <p>{{ accordionContent }}</p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: ["accordionTitle", "accordionContent"],
  data() {
    return {
      isOpen: false,
      faqTitle: "",
      introText: "",
      faqText: "",
      errors: [],
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  padding: 16px 0;
  max-width: 100%;
  width: 100%;
  border-top: 1px solid #303030;
  &:last-of-type {
    border-bottom: 1px solid #303030;
  }
  .accordion-item--inner {
    .accordion-heading {
      h3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font($weight: 700, $size: 1rem, $color: white, $line-height: 24px);

        margin-bottom: 0;
        &:hover {
          cursor: pointer;
          color: $blue;
        }
        &::after {
          content: "\e5cf";
          display: block;
          top: 5px;
          right: 0;
          font-family: "Material Icons";
          font-size: 1.563rem;
        }
        &.open {
          color: $blue;
          &::after {
            color: white;
            transform: rotate(180deg);
          }
        }
      }
    }
    &.open {
      .accordion-content {
        display: block;
        padding-top: 16px;
      }
    }
    .accordion-content {
      display: none;
      p {
        color: #a4aaae;
      }
    }
  }
}
</style>
