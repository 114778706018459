<template>
  <div class="sort-bar">
    <span class="text-meta">{{ $t('Sort by') }}</span>
    <div class="form-select--wrap">
      <select name="sort" class="sort" :value="value" @input="onInput">
        <slot></slot>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss" >
.sort-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    display: inline-block;
    color:#A4AAAE;
    margin-right: 15px;
    @include tablet-down {
      display: none;
    }
  }
  .form-select--wrap {
    position: relative;
    max-width: 100%;
    width: 11rem;
    border: 1px solid white;
    overflow: hidden;
    select.sort {
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      color: white;
      line-height: 24px;
      padding: 0.5em;
      border: none;
      cursor: pointer;
      @include appearance();
      background: url("~@/assets/img/icons/dropdown.svg") no-repeat;
      background-size: auto 6px;
      background-position: right 10px center;
      option {
        color: $primary;
      }
    }
  }
}
</style>
