<template>
  <div class="footer-nav--wrap" :class="isOpen ? 'open' : ''">
    <h6 @click="toggleAccordion()" :class="isOpen ? 'open' : ''">{{$t('We Move Digital')}}</h6>
    <nav class="footer-nav">
      <ul class="menu">
        <li v-for="(menuItem, i) in menuLinks" :key="i">
          <router-link :to="menuItem.slug">{{ menuItem.title }}</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    menuLinks(){
      return [
        { slug: "/legal", title: this.$t('links.legal') },
        { slug: "/privacy", title: this.$t('links.privacy') },
        { slug: "/cookies", title: this.$t('links.cookies') },
      ];
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
</style>
