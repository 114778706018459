<template>
  <section class="block">
    <div class="container grid-container">
      <div :class="'item row' + (gap ? ' gap' : '')">
        <header v-if="blockHeading" class="heading holder " :class="{ flexDisplay,colPadding}">
          <h2 v-if="heading" :class="{ marginBottom}">{{ heading }}</h2>
          <h3 v-if="headingSmall" :class="{ marginBottom}">{{ headingSmall }}</h3>
          <div v-if="filterPanel" class="filter-panel">
            <slot name="filters" />
            <!-- Hier komen filter componenten  -->
          </div>
          <router-link v-if="showMore" :to="showMore"><span>{{$t('show_more')}}</span> </router-link>
        </header>
        <!-- This is slot is for grid cards -->
        <div class="content-container">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    container: Boolean,
    bgGrey: Boolean,
    bgDark: Boolean,
    blockHeading: Boolean,
    heading: String,
    headingSmall: String,
    filterPanel: Boolean,
    showMore: String,
    flexDisplay: Boolean,
    colPadding: {
      type: Boolean,
      default: false
    },
    marginBottom: {
      type: Boolean,
      default: false
    },
    gap: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style lang="scss">
.block {
  position: relative;
  width: 100%;

  &.bgGrey {
    background: #1f1f1f;
  }
  &.bgDark {
    background: #000000;
  }
  .container {
    .row {
      margin-right: -12px;
      margin-left: -12px;
      &.gap{
        gap: 24px;
      }
      @include tablet-down {
        margin-right: -6px;
        margin-left: -6px;
      }
      .heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        padding-bottom: 40px;
        &.flexDisplay {
          display: flex !important;
        }
        h2, h3{
          &.marginBottom{
            @include tablet-down {
              margin-bottom:16px;
            }
          }
        }
        @include tablet-down {
          display: block;
          padding: 0 15px;
          padding-bottom: 36px;
        }
        a {
          text-align: right;
          span {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            font-size: 1rem;
            font-weight: 400;
            color: $blue;
            line-height: 24px;
            &::before {
              content: url("~@/assets/img/icons/arrow-right-blue.svg");
              padding-top: 5px;
              padding-right: 12px;
              line-height: 0;
            }
          }
        }
        .filter-panel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include tablet-down {
            justify-content: flex-start;
          }
        }
      }
    }
    .content-container {
      display: flex;
      flex-wrap: wrap;
      .column-card {
        width: 33%;
      }
    }
  }
}
</style>
