<template>
  <SiteMain noPadding>
    <Hero/>

    <GridSection class="phase--overview bgDark"
                 blockHeading
                 :headingSmall="$t('What are you looking for?')"
                 flexDisplay>
      <PhaseBlock/>
    </GridSection>

    <GridSection
      blockHeading
      :headingSmall="latestSectionTitle"
      :showMore="latestContentSlug"
      flexDisplay
      class="home-latest--content bgDark">
      <div v-if="posts.length" class="carousel">
        <Card
          v-for="(post, i) in posts.slice(0, 3)"
          :key="i"
          :title="post.title"
          :image="post.image"
          :link="post.slug"
          :duration="post.view_time"
          :phase="post.phase_title"/>
      </div>
    </GridSection>
    <VideoPanel v-if="bannerVideo" :videoPath="bannerVideo" :panelText="videoPanelText"/>

    <CtaBlock v-if="cta != null" :title="cta.title" :text="cta.text" :buttonText="cta.button_text"
              :buttonLink="cta.button_url"/>
    <Faq class="background-blend" :title="faqPage.title" :description="faqPage.introduction" :filter-enabled="false" :filter-default="faqDefault" :padding-enabled="true">
      <div class="faq-cta--btn">
        <p class="text-small">{{ $t("Didn't find what you are looking for?") }}</p>
        <router-link :to="`/faq`" class="btn-default">
          <span>{{ $t('More specific FAQ') }}</span>
        </router-link>
      </div>
    </Faq>
  </SiteMain>
</template>

<script>
import SiteMain from "@/components/SiteMain.vue";
import Hero from "@/components/Hero.vue";
import GridSection from "@/components/GridSection.vue";
import Card from "@/components/Card.vue";
import CtaBlock from "@/components/CtaBlock.vue";
import VideoPanel from "@/components/VideoPanel.vue";
import Faq from "@/components/Faq.vue";
import PageLayout from "@/components/PageLayout.vue";
import PhaseBlock from "@/components/PhaseBlock.vue";

export default {
  name: "Home",
  components: {
    PhaseBlock,
    PageLayout,
    Faq,
    SiteMain,
    Hero,
    GridSection,
    Card,
    CtaBlock,
    VideoPanel,
  },
  data() {
    return {
      latestSectionTitle: this.$t("Latest"),
      latestContentSlug: "/content",
    };
  },
  computed: {
    videoPanelText(){
      return this.$store.state.cache.data[`home${this.$i18n.locale}`]?.page?.banner_title ?? '';
    },
    bannerVideo(){
      return this.$store.state.cache.data[`home${this.$i18n.locale}`]?.page?.banner_video ?? '';
    },
    faqDefault(){
      return this.$store.state.cache.data[`home${this.$i18n.locale}`]?.page?.faq_default ?? 'general';
    },
    faqPage(){
      return this.$store.state.cache.data[`faq${this.$i18n.locale}`]?.page ?? '';
    },
    cta(){
      const data = this.$store.state.cache.data[`home${this.$i18n.locale}`];
      return data?.page?.cta?.length > 0 ? data.page?.cta[0] : null;
    },
    posts(){
      return this.$store.state.cache.data[`content_recommended${this.$i18n.locale}`]?.contents ?? [];
    },
  },
  methods: {
    async loadHome() {
      let key = `home${this.$i18n.locale}`;
      let url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=home`;
      this.$store.commit('cacheRequest', {key, url});
      key = `content_recommended${this.$i18n.locale}`;
      url = `${process.env.VUE_APP_API_URL}/api/content/recommended?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
      key = `faq${this.$i18n.locale}`;
      url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=faq`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadHome();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadHome();
    },
  },
};
</script>

<style lang="scss" scoped>
.phase--overview {
  padding: 56px 0;
  @include tablet-down {
    padding: 48px 0;
  }

  .phase-overview--heading {
    padding-bottom: 32px;
    width: 100%;
    color: white;
    @include tablet-down {
      padding-bottom: 32px;
    }
  }
}

.home-latest--content {
  padding: 80px 0;
  color: white;
  @include tablet-down {
    padding: 48px 0;
  }

  .carousel {
    display: flex;
    width: 100%;
  }
}

.faq-cta--btn {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;

  p {
    color: #a4aaae;
    margin-bottom: 16px;
  }
}
</style>
