<template>
    <router-link to="/">
      <img src="@/assets/img/logos/logo-mercedes.svg" />
    </router-link>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.site-logo {
  max-width: 125px;
  width: 100%;
  @include tablet-down{
    max-width:105px;
  }
  a {
    display: block;
    width: 100%;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
}
</style>