<template>
  <PageLayout container :class="(paddingEnabled ? ' padding-y' : '')">
    <header class="page-header">
      <h2 v-if="title">{{ title }}</h2>
      <div v-if="description" v-html="description"></div>
    </header>
    <section class="page-content--block">
      <div class="block-wrapper">
        <div class="sort-bar--container">
          <SortBy v-if="filterEnabled" v-model="selectedTopics">
            <option value="">{{ $t('Show all') }}</option>
            <option :value="topic.slug" v-for="(topic, i) in topicSelect" :key="i">
              {{ topic.title }}
            </option>
          </SortBy>
        </div>
        <template v-for="post in filteredTopics">
          <Accordion
            v-for="(question, i) in post.faq"
            :accordionTitle="question.question"
            :accordionContent="question.answer"
            :key="i.id"/>
        </template>
        <slot/>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
import SortBy from "@/components/SortBy.vue";
import Accordion from "@/components/Accordion.vue";
import PageLayout from "@/components/PageLayout.vue";

export default {
  name: "Faq",
  props: {
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    filterEnabled: {
      type: Boolean,
      default: true
    },
    filterDefault: {
      type: String,
      default: ''
    },
    paddingEnabled: Boolean,
  },
  components: {
    PageLayout,
    Accordion, SortBy,
    CtaButton,
  },
  data() {
    return {
      selectedTopics: this.filterDefault,
      errors: [],
    };
  },
  computed: {
    filteredTopics: function () {
      const topics = this.$store.state.cache.data[`topics${this.$i18n.locale}`]?.topics ?? [];
      if (this.selectedTopics === "") {
        return topics.filter((post) => post.slug !== 'home');
      }
      let posts = topics.filter((post) => post.slug === this.selectedTopics);
      if (!this.filterEnabled && posts.length > 0 && posts[0].faq.length > 5) {
        posts[0].faq = posts[0].faq.slice(0, 5);
      }
      return posts;
    },
    // remove duplicates in for loop
    topicSelect: function () {
      const topics = this.$store.state.cache.data[`topics${this.$i18n.locale}`]?.topics ?? [];
      const existingIds = {};
      return topics.filter((topic) => {
        if (existingIds[topic.slug]) return false;
        if (this.filterDefault !== "home" && topic.slug === 'home') {
          return false;
        }
        return (existingIds[topic.slug] = true);
      });
    },
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
    async loadTopics() {
      const key = `topics${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/topics/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadTopics();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadTopics();
    },
    filterDefault:function(newVal, oldVal) {
      if(newVal !== oldVal){
        this.selectedTopics = newVal;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.background-blend {
  background: radial-gradient(100% 100% at 10.66% 0%, #00192d 0%, #000000 100%);
}

.padding-y {
  padding: 32px 0;
}

.page-layout {
  .page-header {
    h2 {
      margin-bottom: 16px;
    }

    p {
      color: white;
    }
  }
}

.page-content--block {
  .block-wrapper {
    padding: 0;

    .sort-bar--container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 25px;
      @include tablet-down {
        display: block;
      }

      .form-select--wrap {
        border: 1px solid $blue;
        background: $blue;

        &:hover {
          background: #014880;
        }
      }
    }
  }
}
</style>
