<template>
  <div class="btn-wrap" >
    <a
      v-if="type === 'url'"
      :href="link"
      target="_blank"
      class="bmi-btn" :class="{btnBlack,btnBlue,borderWhite,btnWidth}"
      >
      <span :class="{btnArrow}">{{text}}</span>
    </a>
    <router-link
      v-if="type === 'intern'"
      :to="link && link[0] ==='/' ? link : `/${link}`"
      class="bmi-btn" :class="{btnBlack,btnBlue,borderWhite,btnWidth}"
      >
      <span :class="{btnArrow}">{{text}}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  props:{
    type: String,
    text: String,
    link: String,
    btnBlack: Boolean,
    borderWhite: {
      type: Boolean,
      default: false
    },
    btnBlue: {
      type: Boolean,
      default: false
    },
    btnArrow: {
      type: Boolean,
      default: false
    },
    btnWidth:{
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.bmi-btn{
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: auto;
  margin-top: 32px;
  padding: 12px 32px;
  // font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: white;
  letter-spacing: 0.5px;
  cursor:pointer;
  &.btnWidth{
    @include tablet-down {
      display: block;
    }
  }
  &.btnBlue{
    border: 1px solid #0078D6;
    background: #0078D6;
    &:hover {
      background: #014880;
      border: 1px solid #0078D6;
    }
  }
  &.btnBlack{
    background: #000000;
    &:hover {
      background: #404040;
    }
  }
  &.borderWhite {
    color: $white;
    border: 1px solid $white;
    &:hover {
      border: 1px solid $white;
      background: rgba(255, 255, 255, 0.16);
    }
  }
  span{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &.btnArrow{
      &::before {
        content: url("~@/assets/img/icons/arrow-right.svg");
        padding-right: 12px;
        line-height: 0;
      }
    }
  }
}
</style>
