<template>
  <div id="app">
    <Header v-show="$route.path !== '/login'" />
    <router-view :key="$route.path" />
    <Footer v-show="$route.path !== '/login'" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/_fonts.scss";
@import "@/assets/scss/_defaults.scss";
</style>
