<template>
  <video-background
    :src="videoPath"
    overlay="linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.66%), linear-gradient(90deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 100%)"
    class="video-panel--section">
    <div>
      <div class="content-text--wrap container">
        <div class="col-12 col-lg-7 content-text" v-if="panelText">
          <h4>{{ panelText }}</h4>
        </div>
      </div>
    </div>
  </video-background>
</template>

<script>
export default {
  props: {
    videoPath: String,
    panelText: String,
  },
};
</script>

<style lang="scss" scoped>
.video-panel--section {
  height: 400px;
  @include tablet-down {
    height: 300px;
  }
}
.videobg-content {
  width: 100%;
  position: relative;
  background: #1f1f1f;
  .content-text--wrap {
    position: relative;
    width: 100%;
    padding: 120px 0;
    overflow: hidden;
    @include tablet-down {
      padding: 80px 0;
    }
    z-index: 10;
    .content-text {
      display: flex;
      align-items: center;
      color:white;
      &::before {
        content: url("~@/assets/img/icons/arrow-big.svg");
        line-height: 0;
        padding-right: 30px;
        @include tablet-down {
          padding-right: 16px;
        }
      }
    }
  }
}
</style>