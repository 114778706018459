<template>
  <div @click="loginOpen = !loginOpen" :class="loginOpen ? 'active' : ''" class="user-avatar">
    <span>{{ userName.slice(0, 1) }}</span>
    <div class="dropdown-login" v-show="loginOpen">
      <p>{{ userName }}</p>
      <button v-if="!this.userName" @click="setLogin()">{{$t('links.login')}}</button>
      <button v-else @click="setLogout">{{$t('links.logout')}}</button>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axios";

export default {
  name: "UserAvatar",
  components: {},
  data() {
    return {
      loginOpen: false,
    };
  },
  computed: {
    userName(){
      return this.$store.state.cache.data[`user`]?.data?.name ?? '';
    }
  },
  methods: {
    async setLogout() {
      await axios.post(`${process.env.VUE_APP_API_URL}/api/auth/logout`);
      $cookies.remove("access_token");
      window.location.reload();
    },
    setLogin() {
      this.$router.push("/login");
    },
    async loadPage() {
      if ($cookies.get("access_token")) {
        const key = `user`;
        const url = `${process.env.VUE_APP_API_URL}/api/auth/user`;
        this.$store.commit('cacheRequest', {key, url});
      }
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
    $route() {
      this.loginOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-avatar {
  @include tablet-up {
    position: relative;
  }
  cursor: pointer;

  span {
    width: 32px;
    height: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    background: #0078d6;
  }

  &.active {
    .dropdown-login {
      display: block;
    }
  }

  .dropdown-login {
    width: 100%;
    height: 128px;
    position: absolute;
    top: 63px;
    right: 0;
    padding: 24px 16px;
    background: #fff;
    z-index: 1;
    @include tablet-up {
      width: 320px;
      top: 53px;
    }

    &:before {
      display: block;
      content: "";
      background-image: url("~@/assets/img/icons/arrow.png");
      position: absolute;
      top: -6px;
      right: 20px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      @include tablet-up {
        top: -8px;
        right: 5px;
      }
    }

    p {
      @include font($size: 1rem, $color: #000, $weight: 500);
      margin-bottom: 8px;
    }

    button {
      width: 100%;
      background: transparent;
      border: 1px solid rgb(209, 209, 209);
      padding: 12px 0;
    }
  }
}
</style>
