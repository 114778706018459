<template>
  <div class="about">
    <p>
      {{$t('footer.intro')}}
    </p>
    <div class="d-flex cta-block">
    <CtaButton type="intern" btnBlue :text="$t('Get in touch')" link="/contact" />
    <CtaButton type="intern" borderWhite text='FAQ' link="/faq" />
    </div>
  </div>
</template>

<script>
import CtaButton from "@/components/CtaButton.vue";
export default {
  components: {
    CtaButton,
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding-right: 40px;
  @include tablet-down {
    padding: 0;
    padding-bottom: 30px;
  }
  @include tablet {
    padding-bottom: 50px;
  }
  .cta-block {
    padding-top: 14px;
    .btn-wrap {
      &:first-of-type {
        margin-right: 16px;
        @include tablet-down {
          margin-right: 12px;
        }
      }
    }
    @include tablet-down {
      padding: 0;
    }
  }
}
</style>
