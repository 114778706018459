<template>
  <header class="site-header" >
    <template v-if="isDesktopView">
      <HeaderDesktop />
    </template>
    <template v-else>
      <HeaderMobile />
    </template>
  </header>
</template>

<script>
import HeaderDesktop from "@/components/header/HeaderDesktop.vue";
import HeaderMobile from "@/components/header/HeaderMobile.vue";

export default {
  name: "Header",
  components: {
    HeaderDesktop,
    HeaderMobile,
  },
  data() {
    return {
      // headerScrolled: false,
      isDesktopView: false,
    };
  },
  methods: {
    // onScroll() {
    //   window.pageYOffset > 50 ? (this.headerScrolled = true) : (this.headerScrolled = false);
    // },
    onResize() {
      this.isDesktopView = window.innerWidth > 992;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },

  beforeMount() {
    // window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.site-header {
  width: 100%;
  background: $primary;
  // border-bottom: 1px solid #303030;
}
</style>