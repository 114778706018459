import {createI18n} from 'vue-i18n'
import nl from "./nl.json";

export const defaultLocale = "nl";

export const locales = [
  'nl',
  // 'en',
  // 'fr'
]

export const languages = {
  Nederlands: nl,
  // English: en,
  // Français: fr,
};

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    nl: nl,
    // en: en,
    // fr: fr,
  },
});

export default i18n
