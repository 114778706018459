<template>
  <div class="header-inner" :class="menuOpen ? 'fixed' : ''">
    <section class="header-top">
      <div class="container">
        <div class="row holder m-0">
          <div class="col p-0 d-flex main-logo--wrap">
            <div class="hamburger-menu" @click="toggle" :class="menuOpen ? 'active' : ''">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <SiteLogo class="logo"/>
          </div>
          <div class="col p-0 d-flex centered-logo--wrap" cols="2">
            <LogoMercedes/>
          </div>
          <div class="col p-0 d-flex user-avatar--wrap">
            <UserAccount/>
          </div>
          <nav class="site-nav" v-show="menuOpen">
            <SearchBar/>
            <ul class="menu">
              <li v-for="(menuItem, i) in menuLinks" :key="i">
                <a v-if="!menuItem.dropdown" @click="() => to(menuItem.to)">{{
                    $t(menuItem.title)
                  }}
                </a>
                <div v-else @click="() => toggleDropdown(menuItem.slug)"
                     :class="'dropdown' + (dropdown[menuItem.slug] ? ' active' : '')">
                  <p>{{ $t(menuItem.title) }}</p>
                  <span :class="`chevron ${dropdown[menuItem.slug] ? 'top' : 'bottom'}`"/>
                </div>
                <ul v-if="menuItem.dropdown" class="dropdown-items" v-show="dropdown[menuItem.slug]">
                  <li v-for="(item, i) in menuItem.items" :key="i">
                    <a @click="() => to(menuItem.to)">{{ item.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
    <section class="header-bottom">
      <div class="container">
        <div class="holder">
          <SearchBar/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SiteLogo from "@/components/SiteLogo.vue";
import LogoMercedes from "@/components/LogoMercedes.vue";
import LanguagePicker from "@/components/LanguagePicker";
import SearchBar from "@/components/header/SearchBar.vue";
import UserAccount from "@/components/header/UserAccount.vue";

export default {
  components: {
    SiteLogo,
    LogoMercedes,
    SearchBar,
    UserAccount,
    LanguagePicker,
  },
  data() {
    return {
      menuOpen: false,
      loginOpen: false,
      dropdown: {
        phase: false,
      }
    };
  },
  computed: {
    menuLinks() {
      const phases = this.$store.state.cache.data[`phases${this.$i18n.locale}`]?.phases ?? [];
      const phaseMenuItems = phases.map(phase => {
        return {
          to: {name: `phase`, params: {slug: phase.slug}},
          title: phase.title
        }
      });
      return [
        {dropdown: false, to: '/', title: "links.overview"},
        {dropdown: false, to: '/our-journey', title: "links.journey"},
        {
          dropdown: true,
          slug: 'phase',
          title: "links.phase",
          items: phaseMenuItems,
        },
        {dropdown: false, to: '/content', title: "links.content"},
        {dropdown: false, to: '/faq', title: "links.faq"},
        {dropdown: false, to: '/contact', title: "links.contact"},
      ]
    },
  },
  methods: {
    async loadPhases() {
      const key = `phases${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/phases/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
    toggle() {
      this.menuOpen = !this.menuOpen;
      const body = document.getElementsByTagName('body')[0];
      if (this.menuOpen) {
        body.classList.add("fixed-position");
      } else {
        body.classList.remove("fixed-position");
      }
    },
    to(to) {
      const body = document.getElementsByTagName('body')[0];
      if (body.classList.contains("fixed-position")) {
        body.classList.remove("fixed-position");
      }
      if (this.$route.path === to) {
        this.menuOpen = !this.menuOpen;
        return;
      }
      this.$router.push(to);
    },
    toggleDropdown(slug) {
      this.dropdown[slug] = !this.dropdown[slug];
    }
  },
  created() {
    this.loadPhases();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPhases();
    },
    $route() {
      this.menuOpen = false;
      this.loginOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-inner {
  width: 100%;
  background: inherit;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .header-top {
    max-width: 100%;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 1px 0 #303030;

    .holder {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .main-logo--wrap {
        align-items: center;
        // Mobile menu icon
        .hamburger-menu {
          cursor: pointer;
          width: 25px;
          position: relative;
          margin-right: 20px;
          z-index: 5000;
          @include tablet-up {
            width: 35px;
          }

          &:after {
            position: absolute;
            top: -2px;
            left: 36px;
            font-size: 28px;
            font-weight: 700;
            color: white;
            line-height: 28px;
          }

          span {
            position: relative;
            display: block;
            width: 100%;
            height: 3px;
            margin-bottom: 6px;
            transform-origin: 4px 0;
            transition: transform 0.7s cubic-bezier(0.1, 0.2, 0.5, 1), background 0.7s cubic-bezier(0.1, 0.2, 0.5, 1),
            opacity 0.7s ease;
            background: $white;
            z-index: 1;

            &:first-child {
              transform-origin: 0 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:nth-last-child(2) {
              transform-origin: 0 100%;
            }
          }

          &.active {
            span {
              @include tablet-up {
                top: -5px;
              }
              opacity: 1;
              transform: rotate(45deg) translate(-6px, -14px);
              background: $white;
            }

            /* Hiding the middle one to make perfect cross */
            span:nth-last-child(3) {
              opacity: 0;
              transform: rotate(0deg) scale(0.2, 0.2);
            }

            /* Change the direction of last one */
            span:nth-last-child(2) {
              top: -4.5px;
              transform: rotate(-45deg) translate(-5px, 14px);
              @include tablet-up {
                top: -3px;
              }
            }
          }
        }

        .logo {
          max-width: 55px;
        }
      }
    }

    .centered-logo--wrap {
      justify-content: center;
    }

    .user-avatar--wrap {
      justify-content: flex-end;
    }

    .site-nav {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      padding-bottom: 10em;
      font-size: 1rem;
      font-weight: 400;
      color: $primary;
      line-height: 24px;
      margin: 0 auto;
      background: $white;
      z-index: 2000;

      &:before {
        display: block;
        content: "";
        background-image: url("~@/assets/img/icons/arrow.png");
        @include position($position: absolute, $top: -6px, $left: 15px);
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
      }

      @include tablet-up {
        font-size: 1.313rem;
      }

      .menu {
        height: 100%;
        overflow: scroll;

        li {
          padding: 16px;
          box-shadow: 0 1px 0 rgba(218, 218, 218, 0.64);

          &:has(.dropdown.active) {
            padding: 16px 0 0 16px;
          }

          .dropdown {
            @include tablet-up {
              position: relative;
            }
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .chevron {
              &:before {
                border-style: solid;
                border-width: 0.125em 0.125em 0 0;
                content: '';
                display: inline-block;
                height: 0.45em;
                position: relative;
                vertical-align: middle;
                width: 0.45em;
              }

              &.top:before {
                transform: rotate(315deg);
                top: 0.15em;
                right: 16px;
              }

              &.bottom:before {
                transform: rotate(135deg);
                top: -0.15em;
              }
            }
          }

          .dropdown-items {
          }
        }

        .language-picker--list {
          margin-left: 16px;
          padding: 16px 0;
          padding-right: 10px;
          color: #a3a3a3;
          border: inherit;
          min-width: 85px;
          // width: 100%;
          background: url("~@/assets/img/icons/dropdown-grey.svg") no-repeat;
          background-size: auto 8px;
          background-position: right center;

          option {
            padding: 10px;
            color: $primary;
          }
        }
      }

      .site-search {
        padding: 16px;
      }
    }
  }

  .header-bottom {
    padding: 12px 0;

    .holder {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
