import { createApp } from "vue";

import "bootstrap/dist/css/bootstrap.css";
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";

import { Plugin } from "vue-responsive-video-background-player";
import VueCookies from "vue-cookies";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/i18n";

const app = createApp({
  render: (h) => h(App),
});
app.use(router);
app.use(store);
app.use(i18n);

app.use(Plugin);
app.use(VueCookies);
app.use(VueGlide);
app.use(require("vue-moment"));

app.mount('#app');
