<template>
  <div v-if="phases.length" class="block">
    <PhaseCard
        v-for="(phase, i) in phases"
        :key="i"
        :title="phase.title"
        :slug="phase.slug"
        :image="phase.image"/>
  </div>
</template>

<script>
import PhaseCard from "@/components/PhaseCard.vue";

export default {
  components: {PhaseCard},
  props: [],
  data() {
    return {
      phaseSliderSettings: {
        showPrevButton: false,
        showNextButton: true,
        perView: 5,
        peek: {before: 0, after: 100},
        gap: 5,
        infinite: false,
        breakpoints: {
          1300: {
            perView: 4
          },
          1075: {
            perView: 3
          },
          850: {
            perView: 2
          },
          600: {
            perView: 1,
            peek: {before: 0, after: 125},
          }
        },
      },
    };
  },
  computed: {
    isMobileOrTablet() {
      return window.matchMedia("(max-width: 1075px)").matches;
    },
    phases() {
      return this.$store.state.cache.data[`phases${this.$i18n.locale}`]?.phases ?? [];
    },
    posts() {
      return this.$store.state.cache.data[`content${this.$i18n.locale}`]?.contents ?? [];
    },
  },
  methods: {
    async loadPhases() {
      let key = `content${this.$i18n.locale}`;
      let url = `${process.env.VUE_APP_API_URL}/api/content/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
      key = `phases${this.$i18n.locale}`;
      url = `${process.env.VUE_APP_API_URL}/api/phases/all?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
    updateButtonVisibility() {
      this.phaseSliderSettings.showPrevButton = this.$refs.slider.glide.index !== 0;
      this.phaseSliderSettings.showNextButton = (this.$refs.slider.glide.index < 2 && this.$refs.slider.glide.settings.perView === 5) ||
          (this.$refs.slider.glide.index < 3 && this.$refs.slider.glide.settings.perView === 4);
    },
  },
  created() {
    this.loadPhases();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPhases();
    },
  },
};
</script>

<style lang="scss">
.block {
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  padding: 0 12px;
}
</style>
