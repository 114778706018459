<template>
  <select id="language-picker" name="language switcher" class="language-picker--list" v-model="$i18n.locale">
    <option value="fr">Français</option>
    <option value="en">English</option>
    <option value="nl">Nederlands</option>
  </select>
</template>

<script>
export default {
  name: "LanguagePicker",
  data() {
    return {};
  },
  mounted(){
    const languagePicker = document.getElementById('language-picker');
    languagePicker.addEventListener('change', ()=>{
      $cookies.set("locale", languagePicker.value);
    });
  }
};
</script>

<style lang="scss" scoped>
.language-picker--list {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
  line-height: normal;
  padding: 0;
  padding-top: 2px;
  padding-right: 10px;
  cursor: pointer;
  border: none;
  -webkit-appearance: none;
  background: none;
  background: url("~@/assets/img/icons/dropdown.svg") no-repeat;
  background-size: auto 6px;
  background-position: right center;
  option {
    color: $primary;
  }
  &:focus-visible {
    outline: none;
  }
}
</style>
