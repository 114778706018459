import axios from "@/helpers/axios";

const CacheModule = {
  state: () => ({
    data: {},
    isFetching: {},
  }),
  mutations: {
    cacheRequest(state, {key, url, method = 'GET', data = {}}) {
      state.isFetching = {...state.isFetching, [key]: true};
      axios.request({url, method, data})
        .then(response => {
          state.data = {...state.data, [key]: response.data};
          state.isFetching = {...state.isFetching, [key]: false};
        })
        .catch(e => {
          state.isFetching = {...state.isFetching, [key]: false};
          if(e.response.status === 401){
            $cookies.remove("access_token");
            window.location.reload();
          }
        });
    }
  },
}
export default CacheModule;
