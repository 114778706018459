<template>
  <footer class="site-footer">
    <div class="container">
      <div class="site-logo">
        <router-link to="/">
          <img src="@/assets/img/logos/logo-footer.svg" />
        </router-link>
      </div>
      <div class="footer-inner">
        <div class="col-lg-6 column">
          <FooterAbout />
        </div>
        <div class="col-md-4 col-lg-3 column">
          <FooterNav />
        </div>
        <div class="col-md-4 col-lg-3 column">
          <FooterNavTwo />
        </div>
      </div>
      <FooterBottom />
    </div>
  </footer>
</template>

<script>
import SiteLogo from "@/components/SiteLogo.vue";
import FooterAbout from "@/components/footer/FooterAbout.vue";
import FooterNav from "@/components/footer/FooterNav.vue";
import FooterNavTwo from "@/components/footer/FooterNavTwo.vue";
import FooterBottom from "@/components/footer/FooterBottom.vue";
export default {
  name: "Footer",
  components: {
    SiteLogo,
    FooterAbout,
    FooterNav,
    FooterNavTwo,
    FooterBottom,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.site-footer {
  width: 100%;
  padding: 80px 0;
  background: linear-gradient(180deg, #141414 0%, #000000 100%);
  @include tablet-down {
    padding: 50px 0;
    .site-logo {
      margin: 0 auto;
    }
  }
  .footer-inner {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    .column {
      padding: 0;
      .menu {
        li {
          display: inline-block;
          position: relative;
          padding-right: 24px;
          a {
            &.router-link-active {
              color: white;
            }
            &:hover {
              color: #a4aaae;
            }
          }
        }
      }
    }
    @include tablet-down {
      .footer-nav--wrap {
        border-top: 1px solid#303030;
      }
    }
  }
}
</style>