<template>
  <div class="footer-bottom">
    <div class="footer-inner">
      <div class="copyright">
        <p>© 2022 Mercedes-Benz Financial Services B.V.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style lang="scss" scoped>
.footer-bottom {
  width: 100%;
  border-top: 1px solid #303030;
  @include tablet-up {
    margin-top: 30px;
  }
  .footer-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    @include tablet-down {
      display: block;
      text-align: center;
    }
    .copyright {
      p {
        font-size: 0.875rem;
        color: #a4aaae;
        line-height: 24px;
        margin-bottom: 0;
        @include tablet-down {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
