<template>
  <section class="hero">
    <div class="hero-body">
      <vue-glide v-if="isFetching !== true && slides.length > 1"
                 :bullet="true" :per-view="1" :gap="0" ref="slider" type="carousel">
        <vue-glide-slide v-for="(slide, i) in slides" :key="'slide' + i">
          <video-background v-if="slide.video" :src="slide.video" muted:true class="hero-slide">
            <div class="hero-media--wrapper">
              <div class="overlay"></div>
              <div class="container">
                <div class="hero-content">
                  <div class="line"></div>
                  <h1>{{ slide.title }}</h1>
                  <span class="sub-title" v-if="slide.title.length < 42">{{ slide.sub_title }}</span>
                  <CtaButton type="intern" btnWidth btnArrow btnBlue :text="slide.cta_text" :link="slide.cta_url"/>
                </div>
              </div>
            </div>
          </video-background>
          <div v-else class="hero-slide">
            <div class="hero-media--wrapper">
              <div class="overlay"></div>
              <img :src="slide.image" :alt="slide.title"/>
              <div class="container">
                <div class="hero-content">
                  <div class="line"></div>
                  <h1>{{ slide.title }}</h1>
                  <span class="sub-title" v-if="slide.title.length < 42">{{ slide.phase_title }}</span>
                  <div class="btn-wrap">
                    <router-link
                      :to="{ name: `contentDetails`, params: {slug: slide.slug } }"
                      class="bmi-btn btnWidth btnBlue">
                      <span class="btnArrow"> {{ $t('Read more') }} </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-glide-slide>
        <template v-slot:control>
          <button class="previous" data-glide-dir="<"><img src="~@/assets/img/icons/previous.svg"
                                                           alt="previous button"/></button>
          <button class="next" data-glide-dir=">"><img src="~@/assets/img/icons/next.svg" alt="next button"/></button>
        </template>
      </vue-glide>
      <video-background v-if="slides.length === 1 && slides[0].video" :src="slides[0].video" muted:true class="video">
        <div class="hero-media--wrapper">
          <div class="overlay"></div>
          <div class="container">
            <div class="hero-content">
              <div class="line"></div>
              <h1>{{ slides[0].title }}</h1>
              <span class="sub-title" v-if="slides[0].title.length < 42">{{ slides[0].sub_title }}</span>
              <CtaButton type="intern" btnWidth btnArrow btnBlue :text="slides[0].cta_text" :link="slides[0].cta_url"/>
            </div>
          </div>
        </div>
      </video-background>
      <div v-else-if="slides.length === 1" class="image">
        <div class="hero-media--wrapper">
          <div class="overlay"></div>
          <img :src="slides[0].image" :alt="slides[0].title"/>
          <div class="container">
            <div class="hero-content">
              <div class="line"></div>
              <h1>{{ slides[0].title }}</h1>
              <span class="sub-title" v-if="slides[0].title.length < 42">{{ slides[0].phase_title }}</span>
              <div class="btn-wrap">
                <router-link
                  :to="{ name: `contentDetails`, params: {slug: slides[0].slug } }"
                  class="bmi-btn btnWidth btnBlue">
                  <span class="btnArrow"> {{ $t('Read more') }} </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Glide, GlideSlide} from "vue-glide-js";
import VideoPlayer from "@/components/VideoPlayer.vue";
import CtaButton from "@/components/CtaButton.vue";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    VideoPlayer,
    CtaButton,
  },
  computed: {
    isFetching() {
      return this.$store.state.cache.isFetching[`home${this.$i18n.locale}`] ||
        this.$store.state.cache.isFetching[`hero_highlights${this.$i18n.locale}`];
    },
    slides() {
      if (this.isFetching ||
        !this.$store.state.cache.data[`hero${this.$i18n.locale}`]?.page?.hero_videos ||
        !this.$store.state.cache.data[`hero_highlights${this.$i18n.locale}`]?.contents) {
        return [];
      }
      const heroVideos = this.$store.state.cache.data[`hero${this.$i18n.locale}`]?.page?.hero_videos ?? [];
      const heroPosts = this.$store.state.cache.data[`hero_highlights${this.$i18n.locale}`]?.contents ?? [];
      return [...heroVideos, ...heroPosts];
    },
  },
  methods: {
    loadHero() {
      let key = `hero${this.$i18n.locale}`;
      let url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=home`;
      this.$store.commit('cacheRequest', {key, url});
      key = `hero_highlights${this.$i18n.locale}`;
      url = `${process.env.VUE_APP_API_URL}/api/content/highlights?locale=${this.$i18n.locale}`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadHero();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadHero();
    },
  },
};
</script>

<style lang="scss">
.hero {
  position: relative;
  width: 100%;
  color: white;
  background-color: #000000;

  .hero-body {
    width: inherit;

    /* Multiple slide styling */
    .glide {
      .hero-slide {
        max-height: 55vh;
        height: 100vh;
        @media (max-height: 1250px) {
          max-height: 50vh;
        }
        @media (max-height: 1000px) {
          max-height: 50vh;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .videobg-content{
            position: relative;
          }
          .hero-media--wrapper{
            padding: 0;
            .container{
              width: 75%;
            }
            h1{
              font-size: 48px;
            }
          }
        }
        @media (max-height: 800px) {
          max-height: 50vh;
        }
        @include mobiletablet {
          max-height: 468px;
          .videobg-content{
            width: 100%;
          }
          .hero-media--wrapper{
            width: 100%;
            h1{
              font-size: 36px;
            }
          }
        }
      }

      .hero-media--wrapper {
        padding: 0 2em;

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.66%),
          linear-gradient(90deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 100%);
          z-index: 1;
          @include tablet-down {
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 30.34%, rgba(0, 0, 0, 0) 100%);
          }
        }

        .video-container {
          width: 100%;
          height: 100%;
          position: relative;
        }

        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: center / cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .hero-content {
          max-width: 1200px;
          width: 100%;
          position: absolute;
          bottom: 5%;

          margin-bottom: 50px;
          z-index: 4;
          @media (max-height: 1250px) {
            margin-bottom: 30px;
          }
          @media (max-height: 1000px) {
            margin-bottom: 30px;
          }
          @media (max-height: 800px) {
            margin-bottom: 10px;
          }
          @include mobiletablet {
            margin-bottom: 50px!important;
          }
          @include tablet-down {
            top: unset;
            left: 0;
            bottom: 0;
            right: 0;

            padding: 0 16px;
          }

          .line {
            height: 4px;
            width: 72px;
            background-color: #fff;
            margin-bottom: 16px;
          }

          .title {
            font-size: 3.5rem;
            font-weight: 400;
            color: white;
            line-height: 64px;
            @include tablet-down {
              font-size: 2.5rem;
              line-height: 48px;
            }
            margin: 0;
          }

          .sub-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            color: #a4aaae;
            line-height: 24px;
            margin-top: 25px;
            @include tablet-down {
              margin: 12px 0;
            }
          }

          .bmi-btn {
            position: relative;
            display: inline-block;
            max-width: 100%;
            width: auto;
            margin-top: 32px;
            padding: 12px 32px;
            // font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: white;
            letter-spacing: 0.5px;
            cursor: pointer;
            border-radius: 2px;

            &.btnWidth {
              @include tablet-down {
                display: block;
              }
            }

            &.btnBlue {
              border: 1px solid #0078D6;
              background: #0078D6;

              &:hover {
                background: #014880;
                border: 1px solid #0078D6;
              }
            }

            span {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;

              &.btnArrow {
                &::before {
                  content: url("~@/assets/img/icons/arrow-right.svg");
                  padding-right: 12px;
                  line-height: 0;
                }
              }
            }
          }
        }
      }

      div[data-glide-el~="controls"] {
        max-width: 1505px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 2000;
        @include mobiletablet {
          display: none;
        }
        @media (max-height: 1000px) {
          max-width: calc(75% + 100px);
        }

        button {
          position: absolute;
          border: none;
          background: transparent;

          &:hover {
            cursor: pointer;
          }

          &.previous {
            left: 0;
          }

          &.next {
            right: 0;
          }
        }
      }

      .glide__bullets {
        max-width: 1400px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 48px;
        right: 0;
        margin: 0 auto;
        padding: 0 16px;

        @media (max-height: 1000px) {
          bottom: 0;
          max-width: 75%;
        }

        @include mobiletablet {
          max-width: 100%;
        }

        @include tablet-down {
          bottom: 16px;
        }

        .glide__bullet {
          width: 390px;
          height: 2px;
          border: none;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          margin: 0 0.25em;
          background: rgba(255, 255, 255, 0.32);

          &.glide__bullet--active {
            background-color: $white;
          }
        }
      }
    }

    /* only one video styling */
    .video {
      max-height: 55vh;
      height: 100vh;
      @media (max-height: 1250px) {
        max-height: 50vh;
      }
      @media (max-height: 1000px) {
        max-height: 50vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .videobg-content{
          position: relative;
        }
        .hero-media--wrapper{
          padding: 0;
          .container{
            width: 75%;
          }
          h1{
            font-size: 48px;
          }
        }
      }
      @media (max-height: 800px) {
        max-height: 50vh;
      }
      @include mobiletablet {
        max-height: 468px;
        .videobg-content{
          width: 100%;
        }
        .hero-media--wrapper{
          width: 100%;
          h1{
            font-size: 36px;
          }
        }
      }

      .hero-media--wrapper {
        padding: 0 2em;

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.66%),
          linear-gradient(90deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 100%);
          z-index: 1;
          @include tablet-down {
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 30.34%, rgba(0, 0, 0, 0) 100%);
          }
        }

        .video-container {
          width: 100%;
          height: 100%;
          position: relative;
        }

        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: center / cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .hero-content {
          max-width: 1200px;
          width: 100%;
          position: absolute;
          bottom: 5%;

          margin-bottom: 50px;
          z-index: 4;
          @media (max-height: 1250px) {
            margin-bottom: 30px;
          }
          @media (max-height: 1000px) {
            margin-bottom: 30px;
          }
          @media (max-height: 800px) {
            margin-bottom: 10px;
          }
          @include mobiletablet {
            margin-bottom: 50px!important;
          }
          @include tablet-down {
            top: unset;
            left: 0;
            bottom: 0;
            right: 0;

            padding: 0 16px;
          }

          .line {
            height: 4px;
            width: 72px;
            background-color: #fff;
            margin-bottom: 16px;
          }

          .title {
            font-size: 3.5rem;
            font-weight: 400;
            color: white;
            line-height: 64px;
            @include tablet-down {
              font-size: 2.5rem;
              line-height: 48px;
            }
            margin: 0;
          }

          .sub-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            color: #a4aaae;
            line-height: 24px;
            margin-top: 25px;
            @include tablet-down {
              margin: 12px 0;
            }
          }

          .bmi-btn {
            position: relative;
            display: inline-block;
            max-width: 100%;
            width: auto;
            margin-top: 32px;
            padding: 12px 32px;
            // font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: white;
            letter-spacing: 0.5px;
            cursor: pointer;
            border-radius: 2px;

            &.btnWidth {
              @include tablet-down {
                display: block;
              }
            }

            &.btnBlue {
              border: 1px solid #0078D6;
              background: #0078D6;

              &:hover {
                background: #014880;
                border: 1px solid #0078D6;
              }
            }

            span {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;

              &.btnArrow {
                &::before {
                  content: url("~@/assets/img/icons/arrow-right.svg");
                  padding-right: 12px;
                  line-height: 0;
                }
              }
            }
          }
        }
      }
    }

    /* only one image styling */
    .image {
      max-height: 55vh;
      height: 100vh;
      @media (max-height: 1250px) {
        max-height: 50vh;
      }
      @media (max-height: 1000px) {
        max-height: 50vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .videobg-content{
          position: relative;
        }
        .hero-media--wrapper{
          padding: 0;
          .container{
            width: 75%;
          }
          h1{
            font-size: 48px;
          }
        }
      }
      @media (max-height: 800px) {
        max-height: 50vh;
      }
      @include mobiletablet {
        max-height: 468px;
        .videobg-content{
          width: 100%;
        }
        .hero-media--wrapper{
          width: 100%;
          h1{
            font-size: 36px;
          }
        }
      }

      .hero-media--wrapper {
        padding: 0 2em;

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 62.66%),
          linear-gradient(90deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 100%);
          z-index: 1;
          @include tablet-down {
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 30.34%, rgba(0, 0, 0, 0) 100%);
          }
        }

        .video-container {
          width: 100%;
          height: 100%;
          position: relative;
        }

        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: center / cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .hero-content {
          max-width: 1200px;
          width: 100%;
          position: absolute;
          bottom: 5%;

          margin-bottom: 50px;
          z-index: 4;
          @media (max-height: 1250px) {
            margin-bottom: 30px;
          }
          @media (max-height: 1000px) {
            margin-bottom: 30px;
          }
          @media (max-height: 800px) {
            margin-bottom: 10px;
          }
          @include mobiletablet {
            margin-bottom: 50px!important;
          }
          @include tablet-down {
            top: unset;
            left: 0;
            bottom: 0;
            right: 0;

            padding: 0 16px;
          }

          .line {
            height: 4px;
            width: 72px;
            background-color: #fff;
            margin-bottom: 16px;
          }

          .title {
            font-size: 3.5rem;
            font-weight: 400;
            color: white;
            line-height: 64px;
            @include tablet-down {
              font-size: 2.5rem;
              line-height: 48px;
            }
            margin: 0;
          }

          .sub-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            color: #a4aaae;
            line-height: 24px;
            margin-top: 25px;
            @include tablet-down {
              margin: 12px 0;
            }
          }

          .bmi-btn {
            position: relative;
            display: inline-block;
            max-width: 100%;
            width: auto;
            margin-top: 32px;
            padding: 12px 32px;
            // font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: white;
            letter-spacing: 0.5px;
            cursor: pointer;
            border-radius: 2px;

            &.btnWidth {
              @include tablet-down {
                display: block;
              }
            }

            &.btnBlue {
              border: 1px solid #0078D6;
              background: #0078D6;

              &:hover {
                background: #014880;
                border: 1px solid #0078D6;
              }
            }

            span {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;

              &.btnArrow {
                &::before {
                  content: url("~@/assets/img/icons/arrow-right.svg");
                  padding-right: 12px;
                  line-height: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
