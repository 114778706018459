<template>
  <main class="site-content" :class="{ noPadding }">
    <slot />
  </main>
</template>

<script>
export default {
  props: {
    noPadding: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.site-content {
  padding: 72px 0;
  flex:1;
  @include tablet-down {
    padding: 32px 0;
  }
  background: radial-gradient(100% 100% at 10.66% 0%, #00192d 0%, #000000 100%);
  &.noPadding {
    padding: 0;
  }
}
</style>
