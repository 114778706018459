<template>
  <div class="scale-video">
    <video v-if="video.length" :key="video" data-tag="video" muted playsinline autoplay loop>
      <source :src="video" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: ["video"],
};
</script>

<style lang="scss" >
.scale-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  @include tablet-down {
    height: 100%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
// video {
//   width: 100%;
//   height: auto;
//   -o-object-fit: cover;
//   object-fit: cover;
//   // max-height: 371px;
//   object-fit: cover;
//   @include tablet-down {
//     width: auto;
//     height: 105vw;
//     object-fit: fill;
//     max-height: auto;
//   }
// }
</style>
